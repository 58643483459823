.external-events-wrapper {
    display: flex;
    justify-content: center;
    padding: 6px;
    width: 100%;
    flex-wrap: wrap;
}

.external-event {
    border-radius: 8px;
    background-color: hotpink;
    padding: 6px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 8px 0px 8px 0px;
    cursor: pointer;
}