@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Mulish);

body {
    font-family: 'Open Sans', 'sans-serif';
    color: #252733;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: bold;
    margin-bottom: 16px;
    color: #252733;
}

.center-wrapper {
    display: flex;
    justify-content: center;
    min-width: 100%;
    flex-wrap: wrap;
}

.bold {
    font-weight: bold;
}

.main-flex {
    display: flex;
}

.no-padding {
    padding: 0px;
}

.nav-col {
    max-width: 255px;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
}

.content-col {
    padding: 24px 20px 24px 20px;
    background-color: #F8F8F8;
}

.cursor-pointer {
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
}

.cursor-pointer-only {
    cursor: pointer !important;
}

.default-input {
    min-height: 50px;
    margin-bottom: 16px;
}

.no-results-table {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    flex-wrap: wrap;
    background-color: #ffffff;
    border-top: 1px solid rgba(63, 63, 68, 0.13);
}

.no-results-table img {
    width: 150px;
    height: 150px;
}

.no-results-table p {
    width: 100%;
    text-align: center;
} 

.user-menu-wrapper {
    position: absolute;
    top: 45px;
    left: -50px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #ffffff;
    min-width: 150px;
    border-radius: 4px;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    z-index: 2;
  }
  
  .user-menu-wrapper p {
    margin: 0px;
    padding: 2px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-radius: 4px;
  
  }
  
  .user-menu-wrapper p:hover {
    background-color: #9fa2b465
  }

  
.menu-col {
    position: relative;
  }
  

table td {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
letter-spacing: -0.05px;
}
