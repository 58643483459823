.search-row {
  margin-bottom: 24px;
}

.search-input {
  min-height: 36px;
  padding-left: 44px;
}

.search-icon {
  position: absolute;
  bottom: 21px;
  left: 30px;
}

.plus-icon {
  margin-right: 9px;
}

.add-user-col {
  display: flex;
  justify-content: flex-end;
}

.add-user-btn {
  height: 36px;
  width: 159px;
  background-color: #D91A32;
  color: #ffffff;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}


table {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  margin-bottom: 0px !important;
}

.table td {
  padding: 0px;
  vertical-align: middle;
}

.table thead th {
  border-bottom: 0px;
  border-top: 0px;
}

.table-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3A3B3F;
}
.table-header tr {
  height: 56px !important;
}

.table-header th {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3A3B3F;
  padding: 18px;
}

tr {
  height: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3A3B3F;
  padding: 18px;

}

.table-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  min-height: 56px;
  border-top: 1px solid #dee2e6;
  align-items: center;
  padding: 0px 53px 0px 53px;
}

.table-end p {
  margin: 0px;
}

.rows-per-page {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #9FA2B4;
  display: flex;
  align-items: flex-end;
}

.next-page {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #9FA2B4;
  margin-left: 48px;
}

.next-icons {
  cursor: pointer;
  margin: 0px 12px 0px 0px; 
}

.row-icon {
  color: #9194a5;
  cursor: pointer;
}

.menu-icon {
  color: #9FA2B4;
  cursor: pointer;
}

.remove-icon {
  margin-left: 6px;
  cursor: pointer;
  color: #D91A32;
}




