.new-job-wrapper {
  width: 840px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(63, 63, 68, 0.005);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  padding: 26px 36px 26px 36px;
}

.main-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin: 0px;
}

.subtitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 24px;
}

.job-input {
  height: 50px;
  border-radius: 4px;
  margin-bottom: 24px;
}

label {
  font-size: 16px;
  line-height: 21.79px;
  font-weight: 600;
  margin-bottom: 8px;
}

.add-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 18px 0px;
}

.add-button {
  background-color: transparent;
  color: #D91A32;
  font-size: 16px;
  line-height: 21.79px;
  font-weight: 600;
  border: transparent;
}

.add-button:hover {
  background-color: transparent;
  color: #D91A32;
  font-size: 16px;
  line-height: 21.79px;
  font-weight: 600;
  border: transparent;
}

.flex-end-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 13px 0px 12px 0px;
}

.flex-end-wrapper p {
  font-size: 16px;
  line-height: 21.79px;
  font-weight: 600;
  margin: 0px;
}

.bottom {
  padding: 0px 0px 12px 0px !important;
}

.border-top {
  border-top: 1px solid rgba(73, 73, 73, 0.3);
}

.border-bottom {
  border-bottom: 1px solid rgba(73, 73, 73, 0.3);
}

.job-item-row {
  padding: 18px 0px 17px 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 3px;
  border-bottom: 1px solid rgba(73, 73, 73, 0.3);
  align-items: center;
}

.job-item-row p {
  margin: 0px;
}

.customer-list-wrapper {
  top: 82px;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  z-index: 2;
  max-height: 170px;
  border: 1px solid rgba(63, 63, 68, 0.568);
  box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
  overflow-y: scroll;
  padding: 8px;
}

.customer-list-wrapper h6 {
  margin-bottom: 6px;
}

.cust-result {
  cursor: pointer;
  border-bottom: 1px solid rgba(63, 63, 68, 0.411);
  padding: 8px 0px 8px 0px;
}

.cust-result p {
  margin-bottom: 1px;
}

.submit-button {
  width: 100%;
  margin-top: 24px;
  background-color: #D91A32;
  color: #ffffff;
  font-size: 17px;
  line-height: 21.79px;
  font-weight: 700;
  height: 44px;
  border: 0px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 0px;
}

.selected-tech {
  margin-left: 5px;
  padding: 5px;
  background-color: #D91A32;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}

.delete-col {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.edit-icon {
  color: #5016d6;
  cursor: pointer;
}

.check-icon {
  color: #16ca4c;
  cursor: pointer;
}

.delete-icon {
  color: #D91A32;
  cursor: pointer;
}

.in-prog {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: #FEC400;
  border-radius: 100px;
  margin: 0px;
  padding: 8px;
  color: #ffffff;
}

.job-update-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.job-status-badge {
  margin: 0px;
  padding: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  border-radius: 100px;
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  font-weight: bold;
  font-size: 11px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  min-width: 150px;
}



.job-number {
  background:rgba(63, 63, 68, 0.411);
  font-size: 14px;
}

.job-status-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.status-icon {
  cursor: pointer;
}

.yellow {
  background: #FEC400;
}

.red {
  background: #D91A32;
}

.purple {
  background: #CC8899;
}

.green {
  background: #1AD92D;
}

.gray {
  background-color: grey;
}

.black {
  background-color: #333;
}

.teal {
  margin-top: 6px;
  background: #03c2db;
}

.orange {
  margin-top: 6px;
  background: #f07808;
}

.commercial-p {
  background-color: #03c2db;
  border-radius: 100px;
  padding: 5px 16px 5px 16px;
  margin: 0px;
  max-width: 150px;
  color: white;
  text-align: center;
  font-weight: 500;
}

.normal {
  margin: 0px;
  text-align: center;
}

.flex-td {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 55px;
}

.pdf-wrapper {
  width: 100%;
  height: 100%;
}

.photos-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.photo-preview {
  margin: 8px;
  position: relative;
}

.photo-delete-icon {
  color: #D91A32;
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 2;
  cursor: pointer;
}

.photo-preview img {
  max-width: 125px;
  max-height: 125px;
  cursor: pointer;

}

.photo-preview .img-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.date-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.toggle-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.toggle-label {
  margin: 0 8px 0px 0px;
}

.remove-cust-icon {
  position: absolute;
  width: 25px;
  height: 25px;
  color: #D91A32;
  top: 43px;
  right: 40px;
  cursor: pointer;
}

.modal-btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .new-job-wrapper {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
    padding: 8px;
  }

  .job-update-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .content-col {
    padding: 0px;
  }

  .photos-wrapper {
    justify-content: center;
  }
}