.update-btn-wrapper {
  margin: 8px 0px 16px 0px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.update-btn {
  min-height: 50px;
  min-width: 311px;
  background-color: #D91A32;
  color: #ffffff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-btn:hover {
  background-color: #d91a34e3;
}

.address-auto-complete {
  position: absolute;
  background-color: white;
  max-height: 150px;
  max-width: 100%;
  z-index: 2;
  padding: 6px;
  overflow: auto;
}

.remove-addy {
  color: #D91A32;
  position: absolute;
  top: 43px;
  right: 30px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.view {
  top: 38px;
  right: 25px;
}

input {
  margin-bottom: 12px;
}

.center-job-loc {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.table td {
  height: 55px;
}