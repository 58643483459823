.login-main-container {
    width: 90vw;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.login-logo-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 36px;
}

.login-input {
    min-height: 50px;
    min-width: 311px;
    margin-bottom: 16px;
}

.login-btn {
    min-height: 50px;
    min-width: 311px;
    background-color: #D91A32;
    color: #ffffff;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-btn:hover {
    background-color: #d91a34e3;
}

.forgot-password-wrapper {
    margin-top: 24px;
    cursor: pointer;
}

.forgot-password-wrapper:hover {
    text-decoration: underline;
}

.reset-submit-btn {
    min-height: 50px;
    min-width: 311px;
    background-color: #D91A32;
    color: #ffffff;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}
