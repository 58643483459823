    .top-bar {
        position: relative;
        display: flex;
        top: 0;
        min-width: 100%;
        background-color: #ffffff;
        padding: 4px 33px 12px 33px;
        align-items: center;
    }

    .nav-logo-wrapper {
        max-width: 215px;
    }
    
    .page-title-wrapper {
        display: flex;
        justify-content: flex-start;
        width: 215px;
        margin-left: 30px;
    }
    
    .page-title {
        font-size: 24px;
        line-height: 33px;
        font-weight: bold;
        margin: 0px;
    }
    
    .user-info-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }
    
    .text-wrapper {
        margin-right: 14px
    }
    
    .user-info-wrapper p {
        margin: 2px;
        font-size: 14px;
        line-height: 20px;
    }
    
    .position-text {
        font-size: 14px !important;
        line-height: 14px !important;
    }
    
    .account-hover {
        position: relative;
        display: flex;
        cursor: pointer;
    }
    
    .chev-icon {
        margin-left: 8px;
    }
    
    .usermenu-pop {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 16px;
        width: 170px;
    }
    
    .usermenu-nav {
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    
    .usermenu-nav:hover {
        background-color: #75778618;
    }
    
    .usermenu-nav p {
        font-size: 16px !important;
        padding: 5px;
    }
    
    
    .nav-main-wrapper {
        margin-top: 33px;
        position: relative;
        min-height: 100vh;
        z-index: 2;
        background-color: #ffffff;
        min-width: 100%;
        padding-left: 15px;
    }
    
    .nav-main-wrapper p {
        margin: 0 0 0 8px;
    }
    
  
    
    .nav-link {
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
        padding: 18px 32px 18px 32px;
        display: flex;
        align-items: center;
    }
    
    .nav-link:hover {
        background-color: #75778618;
    }
    
    .active {
        background-color: #75778618;
        color: #D91A32;
        border-left: solid 2px #D91A32;
    }


    @media (min-width: 640px) and (max-width: 812px) {

        .top-bar {
            position: relative;
            display: flex;
            top: 0;
            min-width: 100%;
            background-color: #ffffff;
            padding: 8px 8px 24px 8px;
            align-items: center;
        }
  
        .nav-col {
            max-width: 50px;
            display: flex;
            justify-content: center;
            background-color: #ffffff;
            display: none;
        }

        .user-info-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            margin-top: 16px;
        }

        .user-info-wrapper img {
            display: none;
        }

        .usermenu-pop {
            background-color: #ffffff;
            border-radius: 8px;
            padding: 8px;
            width: 130px;
        }
        
    }

    @media (min-width: 320px) and (max-width: 480px) {

        .top-bar {
            margin-top: 30px;
            position: relative;
            display: flex;
            top: 0;
            min-width: 100%;
            background-color: #ffffff;
            padding: 8px 8px 24px 8px;
            align-items: center;
        }
  
        .nav-col {
            max-width: 50px;
            display: flex;
            justify-content: center;
            background-color: #ffffff;
            display: none;
        }

        .user-info-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            margin-top: 16px;
        }

        .user-info-wrapper img {
            display: none;
        }

        .usermenu-pop {
            background-color: #ffffff;
            border-radius: 8px;
            padding: 8px;
            width: 130px;
        }
        
    }

 

