.open-jobs-row {
    margin-top: 16px;
    min-height: 150px;
    margin-bottom: 16px;
}

.tech-card {
    min-width: 250px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.select-background {
    background-color: #FFF;
}

.tech-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    min-height: 150px;
}

.job-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
}

.job-card {
    min-width: 200px;
    min-height: 200px;
    margin: 8px;
}

.card-tile {
 padding: 6px;
 display: flex;
 justify-content: center;
 border-bottom: 1px solid rgba(0,0,0,.2);
}

.card-body {
    padding: 8px;
}

.cursor-hover {
    cursor: pointer;
}

.loading-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}

.loading-wrapper p {
    width: 100%;
    text-align: center;
}

@media (min-width: 320px) and (max-width: 480px) {

    .job-card-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; 
    }
}